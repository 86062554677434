import Navmenu from './components/navmenu/NavMenu'
import FotterButtom from './components/footerBottom/FotterButtom'
import './index.css'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Route, Routes, useLocation } from 'react-router-dom'
import Home from './pages/Home'
import SucursalPage from './pages/SucursalPage'
import MisionVision from './components/misionVision/MisionVision'
import Documentacion from './pages/Documentacion'
import PreguntasFrecuentes from './pages/PreguntasFrec'
import QuienesSomos from './pages/QuienesSomos'
import PromocionesServicio from './pages/PromocionesServicio'
import Rse from './pages/Rse'
import FormSolicitud from './pages/FormSolisitud'
import FormDatosMonto from './components/formularios/FormDatosMonto'
import RseContenido from './pages/RseContenido'
import Reclamo from './pages/Reclamo'
import FormCompleto from './pages/FormCompleto'
import NavForm from './components/navmenu/NavForm'
import FormCompletoCampana from './pages/FormCompletoCampana'
import FormularioCompletoCampana from './components/formularios/FormularioCompletoCampana'

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL_BASE
})
function App () {
  const [datosApi, setPost] = useState([])
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    async function getPost () {
      const response = await client.get(
        process.env.REACT_APP_API_URL_BASE + '/datos/GetDatosWeb'
      )
      setPost(response.data)
      setLoading(false)
    }
    getPost()
  }, [])
  const location = useLocation()
  const urlWeb = location.pathname
  return (
    <div className='App'>
      <div className='App_Body'>
        {urlWeb !== '/form' &&
        urlWeb !==
          '/form?utm_campaign=:utm_campaign?&utm_source=:utm_source?&utm_medium=:utm_medium?&utm_term=:utm_term?' &&
        urlWeb !== '/form2' &&
        urlWeb !==
          'form2?utm_campaign=:utm_campaign?&utm_source=:utm_source?&utm_medium=:utm_medium?&utm_term=:utm_term?' &&
        urlWeb !== 'formCampaña2'
          ? (
            <Navmenu />
            )
          : (
            <NavForm />
            )}

        <Routes>
          <Route
            path='/'
            element={
              <Home
                lista={datosApi.imagenCarrousels}
                contenido={datosApi.footerTexts}
                loading={loading}
              />
            }
          />
          <Route
            path='documento'
            element={
              <Documentacion
                contenido={datosApi.footerTexts}
                documentos={datosApi.documentaciones}
              />
            }
          />
          <Route path='misvis' element={<MisionVision />} />
          <Route
            path='preguntas'
            element={
              <PreguntasFrecuentes
                contenido={datosApi.footerTexts}
                preguntas={datosApi.preguntasFrecuentes}
              />
            }
          />
          <Route
            path='promociones'
            element={
              <PromocionesServicio
                contenido={datosApi.footerTexts}
                imgPromoServ={datosApi.promociones}
              />
            }
          />
          <Route
            path='rse'
            element={
              <Rse contenido={datosApi.footerTexts} data={datosApi.rse} />
            }
          />
          <Route
            path='rseContent'
            element={<RseContenido footer={datosApi.footerTexts} />}
          />

          <Route path='reclamo' element={<Reclamo />} />
          <Route
            path='form'
            element={<FormCompleto contenido={datosApi.footerTexts} />}
          />
          <Route path='formCampaña2' element={<FormularioCompletoCampana />} />
          <Route
            path='form?utm_campaign=:utm_campaign?&utm_source=:utm_source?&utm_medium=:utm_medium?&utm_term=:utm_term?'
            element={<FormCompleto contenido={datosApi.footerTexts} />}
          />
          <Route
            path='form2'
            element={<FormCompletoCampana contenido={datosApi.footerTexts} />}
          />
          <Route
            path='form2?utm_campaign=:utm_campaign?&utm_source=:utm_source?&utm_medium=:utm_medium?&utm_term=:utm_term?'
            element={<FormCompletoCampana contenido={datosApi.footerTexts} />}
          />
        </Routes>
      </div>

      {urlWeb !== '/formularioCompleto' &&
      urlWeb !== '/formulario' &&
      urlWeb !== '/form'
        ? (
          <FotterButtom />
          )
        : undefined}
    </div>
  )
}
export default App
