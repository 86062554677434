import { Grid, Box, Paper, Slide } from '@mui/material'
import React from 'react'
import '../sections/sectionIcons.css'
import wppImg from './sectionImg/icono-whatsapp.png'
import pesosICon from './sectionImg/PESOS.png'
import telefonoICon from './sectionImg/TELEFONO.png'

import { Button } from 'reactstrap'

const SectionsIcons = () => {
  const handleClick = () => {
    window.open('/promociones', '_blank')
  }
  return (
    <div>
      <Slide
        direction='left'
        in
        mountOnEnter
        unmountOnExit
        id='SlideIcons'
        {...{ timeout: 1000 }}
      >
        <Box p={1} paddingBottom={2} paddingTop={2}>
          <Grid container className='section-icons'>
            <Grid item className='section-icons__item'>

              <Paper elevation={5} id='PaperIcon'>
                <Button id='buttonPromociones' onClick={handleClick}>
                  <img alt='WhatsApp' src={pesosICon} className='img_paper' />
                </Button>
              </Paper>

              <h2 id='tituloSecIcon'> Promociones</h2>
            </Grid>
            <Grid item className='section-icons__item'>
              <a href='https://api.whatsapp.com/send?phone=59896902120' target='_blank' rel='noopener noreferrer'>
                <Paper elevation={5} id='PaperIcon'>
                  <Button id='buttonPromociones2'>
                    <img alt='WhatsApp' src={wppImg} className='img_paper' />
                  </Button>

                </Paper>
              </a>
              <h2 id='tituloSecIcon'> WhatsApp</h2>
            </Grid>
            <Grid item className='section-icons__item'>
              <a href='tel:+59808002120' target='_blank' rel='noopener noreferrer'>
                <Paper elevation={5} id='PaperIcon'>
                  <Button id='buttonPromociones3'>
                    <img
                      alt='Teléfono'
                      src={telefonoICon}
                      className='img_paper'
                    />
                  </Button>
                </Paper>
              </a>
              <h2 id='tituloSecIcon'> Teléfono</h2>
            </Grid>
          </Grid>
        </Box>
      </Slide>
    </div>
  )
}
export default SectionsIcons
