import 'bootstrap/dist/css/bootstrap.min.css'
import { Box } from '@mui/material'
import img from '../loading/cyLoading.png'
import '../loading/Loading.css'
import React from 'react'

const Loading = (props) => {
  const { loading } = props

  return (
    <>
      {loading
        ? (
          <Box className='Loading_Box'>
            <div style={{ position: 'relative' }}>
              <div className='Loading_Center_spiner'>
                <div style={{ position: 'relative' }}>
                  <div class='spinner' />
                  <div style={{ position: 'absolute' }}>
                    <img
                      alt='loading'
                      className='Loading_Box_Img'
                      src={img}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Box>
          )
        : (
          <></>
          )}
    </>
  )
}

export default Loading
