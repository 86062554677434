import React, { Component } from 'react'
import {
  Carousel,
  CarouselItem,
  CarouselControl
} from 'reactstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../carousel/Carrousel.css'
class carouselPage extends Component {
  constructor (props) {
    super(props)
    this.state = { activeIndex: 0 }
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
    this.goToIndex = this.goToIndex.bind(this)
    this.onExiting = this.onExiting.bind(this)
    this.onExited = this.onExited.bind(this)
  }

  onExiting () {
    this.animating = true
  }

  onExited () {
    this.animating = false
  }

  next () {
    const { lista } = this.props
    if (this.animating) return
    const nextIndex =
      this.state.activeIndex === lista.length - 1
        ? 0
        : this.state.activeIndex + 1
    this.setState({ activeIndex: nextIndex })
  }

  previous () {
    const { lista } = this.props
    if (this.animating) return
    const nextIndex =
      this.state.activeIndex === 0
        ? lista.length - 1
        : this.state.activeIndex - 1
    this.setState({ activeIndex: nextIndex })
  }

  goToIndex (newIndex) {
    if (this.animating) return
    this.setState({ activeIndex: newIndex })
  }

  render () {
    const { lista } = this.props
    const { activeIndex } = this.state

    const slides = (lista || []).map((item) => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={item.id}
        >

          <img
            src={
              process.env.REACT_APP_API_URL_BASE +
              `/Imagen/ShowImage/${item.id}`
            }
            alt={item.alterText}
            width='100%'
            id='imgCarrousel'
          />
        </CarouselItem>
      )
    })
    return (
      <>
        <div className='rootDiv'>
          <Carousel
            activeIndex={activeIndex}
            next={this.next}
            previous={this.previous}
          >

            {slides}
            <CarouselControl
              direction='prev'
              directionText='Anterior'
              onClickHandler={this.previous}
            />
            <CarouselControl
              direction='next'
              directionText='Siguiente'
              onClickHandler={this.next}
            />
          </Carousel>
        </div>
      </>
    )
  }
}
export default carouselPage
