import React from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import './estilo.css'
import { Grid } from '@mui/material'

const Preguntas = ({ contenido }) => {
  const pregntaYRes = (contenido || []).map((item) => {
    return (
      <Grid item padding='10px'>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1a-content'
            id='panel1a-header'
          >
            <Typography variant='h3' id='panel1a-titulo'>
              {item.titulo}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{item.descripcion}</Typography>
          </AccordionDetails>
        </Accordion>
      </Grid>
    )
  })
  return (
    <>
      <Grid paddingTop='25px'>
        <Grid item id='listaTitulo'>
          <h2 style={{ textAlign: 'center', color: '#00408E', fontSize: '23px' }}>
            PREGUNTAS FRECUENTES
          </h2>
        </Grid>
        {pregntaYRes}
      </Grid>
    </>
  )
}
export default Preguntas
