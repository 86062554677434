import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
// yup para validar los formularios
import { Button, TextField, Grid } from '@mui/material'
import '../formularios/estilo.css'
import { Container } from '@mui/system'
import Loading from '../loading/Loading'
import '../loading/Loading.css'
import InputMask from 'react-input-mask'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const validationSchema = yup.object({
  Cedula: yup
    .string('Ingrese una cédula')
    .test('validator_Ci', function (value) {
      const valido = isDocumentValid(value)
      if (!valido) {
        return this.createError({ message: 'Ingrese una cédula correcta' })
      } else {
        return true
      }
    })
    .required('Ingrese una cédula'),
  Telefono: yup
    .string()
    .matches(phoneRegExp, 'numero de teléfono no valido')
    .min(8, 'ingrese su Teléfono')
    .max(9, 'ingrese un número correcto')
    .required('el campo Nombre es obligatorio')
})

function isDocumentValid (value) {
  value = (value || '').replace(/\D/g, '') // remove non numeric
  value = (value || '').padStart(8, '0') // pad 0 at beginning
  return (
    (2 * value[0] +
      9 * value[1] +
      8 * value[2] +
      7 * value[3] +
      6 * value[4] +
      3 * value[5] +
      4 * value[6] +
      1 * value[7]) %
      10 ===
    0
  )
}

const apiPreLeed = (data) => {
  const obj = {
    Cedula: data.Cedula.replace(/[^0-9]/g, ''),
    Telefono: data.Telefono.replace(/[^0-9]/g, ''),
    UtmCampania: data.UtmCampania,
    UtmFuente: data.UtmFuente,
    UtmMedio: data.UtmMedio,
    UtmTermino: data.UtmTermino
  }

  axios
    .post(process.env.REACT_APP_CONSULTA_API + 'PreConsulta/pre-consulta', obj)
    .then((response) => {
      if (response.data.codigo === 1) {

      } else {

      }
    }
    )
    .catch((error) => {

    })
}

const FormCiCelCampana = (dato) => {
  const { utm_campaign, utm_source, utm_medium, utm_term } = dato.dato
  const location = useLocation
  const [loading, setLoading] = useState()
  const [bandera, setBandera] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 8000)
  })
  const ci = (location().state && location().state.ci) || ''
  const cel = (location().state && location().state.Telefono) || ''

  const formik = useFormik({
    initialValues: {
      Cedula: ci,
      Telefono: cel,
      UtmCampania: utm_campaign,
      UtmFuente: utm_source,
      UtmMedio: utm_medium,
      UtmTermino: utm_term
    },
    validationSchema,
    onSubmit: (values) => {
      apiPreLeed(values)
      setLoading(true)
      setBandera(1)
    }
  })
  const navigator = useNavigate()
  return (
    <>
      {bandera
        ? (
            navigator('/formCampaña2', {
              state: {
                ci: formik.values.Cedula.replace(/[^0-9]/g, ''),
                Telefono: formik.values.Telefono,
                UtmCampania: formik.values.UtmCampania,
                UtmFuente: formik.values.UtmFuente,
                UtmMedio: formik.values.UtmMedio,
                UtmTermino: formik.values.UtmTermino
              }
            })
          )
        : (
          <div>
            <Loading loading={loading} />
            <Container>
              <Grid container className='Grid_Form'>
                <Grid item className='Form_Text'>
                  <h1> ¿NECESITAS EFECTIVO?</h1>
                  <p>Te brindamos un préstamo rápido y sin complicaciones</p>
                </Grid>

                <Grid item xs={12}>
                  <form onSubmit={formik.handleSubmit}>
                    <div className='Form_text_Field'>
                      <InputMask
                        mask='9.999.999-9'
                        value={formik.values.Cedula}
                        onChange={formik.handleChange}
                        disabled={false}
                        maskChar={null}
                      >

                        {() => <TextField
                          fullWidth
                          id='Cedula'
                          name='Cedula'
                          label='Ingrese su cel'
                          value={formik.values.Cedula}
                          onChange={formik.handleChange}
                          error={formik.touched.Cedula && Boolean(formik.errors.Cedula)}
                          helperText={formik.touched.Cedula && formik.errors.Cedula}
                               />}
                      </InputMask>

                    </div>
                    <div className='Form_text_Field'>
                      <TextField
                        fullWidth
                        id='Telefono'
                        name='Telefono'
                        label='Ingrese su cel'
                        value={formik.values.Telefono}
                        onChange={formik.handleChange}
                        error={
                        formik.touched.Telefono && Boolean(formik.errors.Telefono)
                      }
                        helperText={
                        formik.touched.Telefono && formik.errors.Telefono
                      }
                      />
                    </div>
                    <div className='Form_Buttom'>
                      <Button
                        style={{ backgroundColor: '#00408E' }}
                        variant='contained'
                        type='submit'
                      >
                        Pedilo Ya
                      </Button>
                    </div>
                  </form>
                </Grid>
              </Grid>
            </Container>
          </div>
          )}
    </>
  )
}

export default FormCiCelCampana
