import React from 'react'
import { Grid } from '@mui/material'
import Paper from '@mui/material/Paper'
import './estilo.css'

export const CardMV = () => {
  return (
    <>
      <div className='divCardMV'>
        <Paper
          elevation='0'
          id='PaperCardMV'
        >
          <Grid padding='10px'>
            <Grid item id='item_Paper'>
              <div>
                <h1>Misión</h1>
              </div>
              <div>
                <p>
                  Brindar las mejores soluciones financieras del mercado de crédito al consumo y
                  contribuir al progreso de nuestros clientes, comprometiéndonos a proporcionarles
                  soluciones de forma ágil y eficiente de acuerdo a sus necesidades.
                </p>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </div>
      <div className='divCardMV'>
        <Paper
          elevation='0'
          id='PaperCardMV'
        >
          <Grid padding='10px'>
            <Grid item id='item_Paper'>
              <div>
                <h1>Visón</h1>
              </div>
              <div>
                <p>
                  Trabajamos para convertirnos en la administradora de créditos cuyo diferencial es la
                  rapidez, eficacia y el enfoque a las necesidades de los clientes, generando la
                  confianza necesaria para lograr su fidelización con nuestra empresa.
                </p>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </div>
    </>
  )
}

export const ListCardMV = () => {
  return (
    <div className='divCardMV'>
      <Paper
        elevation='0'
        id='PaperCardMV'
      >
        <Grid padding='10px'>
          <Grid item>
            <div id='div_Politica'>
              <h1>
                {' '}
                Política de Calidad
              </h1>
            </div>
            <div id='listMV'>
              <p>
                <dl>
                  <dt>CLIENTES:</dt>
                  <dd>
                    Satisfechos por la eficiencia y rapidez del servicio brindado
                  </dd>
                  <dt>EMPLEADOS:</dt>
                  <dd> Con capacidad de desarrollo y compromiso en la búsqueda de los resultados</dd>
                  <dt>PROVEEDORES:</dt>
                  <dd>
                    Generar una relación de beneficio mutuo que permita cumplir los acuerdos establecidos.
                  </dd>
                  <dt>ACCIONISTAS:</dt>
                  <dd>
                    Generar una relación de beneficio mutuo que permita cumplir los acuerdos establecidos.  <br />
                    Desarrollamos   estos   principios   en   cumplimiento   de   los   requisitos   legales   y
                    reglamentarios, apostando a la MEJORA CONTINUA y al reconocimiento de la sociedad
                    como una organización que cumple con los valores de compromiso, transparencia y
                    adaptabilidad a las necesidades de sus clientes
                  </dd>
                </dl>
              </p>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </div>
  )
}
