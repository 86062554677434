import React, { useState } from 'react'
import { Drawer, Box, IconButton, MenuItem, Divider } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import Logo from './img/LOGO-CREDITO-YA.png'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import { Link } from 'react-router-dom'
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined'
import Groups3OutlinedIcon from '@mui/icons-material/Groups3Outlined'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import OtherHousesOutlinedIcon from '@mui/icons-material/OtherHousesOutlined'
import CampaignIcon from '@mui/icons-material/Campaign'
import PreguntasICon from '@mui/icons-material/QuestionMark'
import SocialIcon from '@mui/icons-material/DoneOutline'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'

const DrawerMenu = () => {
  const [isDraweOpen, setIsDrawerOpen] = useState(false)
  return (
    <>
      <IconButton
        size='large'
        edge='start'
        color='inherit'
        aria-label='logo'
        onClick={() => setIsDrawerOpen(true)}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        PaperProps={{
          sx: {
            backgroundColor: '#00408E'
          }
        }}
        anchor='left'
        open={isDraweOpen}
        onClose={() => setIsDrawerOpen(false)}
      >
        <Box p={2} width='230px' textAlign='center' role='presentation'>
          <img alt='logo' src={Logo} height='26' />
        </Box>
        <Divider />
        <Divider />
        <Divider />
        <MenuItem>
          <Link to='/' onClick={() => setIsDrawerOpen(false)}>
            <div style={{ display: 'flex' }}>
              <HomeOutlinedIcon
                style={{ marginRight: '10px', color: 'white' }}
              />
              <p style={{ color: 'white' }}>Página Principal</p>
            </div>
          </Link>
        </MenuItem>
        <MenuItem p={1}>
          <Link to='promociones' onClick={() => setIsDrawerOpen(false)}>
            <div style={{ display: 'flex' }}>
              <CampaignIcon style={{ marginRight: '10px', color: 'white' }} />
              <p style={{ color: 'white' }}>Promociones</p>
            </div>
          </Link>
        </MenuItem>
        <MenuItem p={1}>
          <Link to='preguntas' onClick={() => setIsDrawerOpen(false)}>
            <div style={{ display: 'flex' }}>
              <PreguntasICon style={{ marginRight: '10px', color: 'white' }} />
              <p style={{ color: 'white' }}>Preguntas Frecuentes</p>
            </div>
          </Link>
        </MenuItem>
        <MenuItem p={1}>
          <Link to='rse' onClick={() => setIsDrawerOpen(false)}>
            <div style={{ display: 'flex' }}>
              <Groups3OutlinedIcon
                style={{ marginRight: '10px', color: 'white' }}
              />
              <p style={{ color: 'white' }}>Rse</p>
            </div>
          </Link>
        </MenuItem>
        <MenuItem p={1}>
          <Link to='misvis' onClick={() => setIsDrawerOpen(false)}>
            <div style={{ display: 'flex' }}>
              <SocialIcon style={{ marginRight: '10px', color: 'white' }} />
              <p style={{ color: 'white' }}>Misión y visión</p>
            </div>
          </Link>
        </MenuItem>
        <MenuItem>
          <Link to='documento' onClick={() => setIsDrawerOpen(false)}>
            <div style={{ display: 'flex' }}>
              <DescriptionOutlinedIcon
                style={{ marginRight: '10px', color: 'white' }}
              />
              <p style={{ color: 'white' }}>Documentación</p>
            </div>
          </Link>
        </MenuItem>
        <MenuItem>
          <Link to='reclamo' onClick={() => setIsDrawerOpen(false)}>
            <div style={{ display: 'flex' }}>
              <QuestionAnswerIcon
                style={{ marginRight: '10px', color: 'white' }}
              />
              <p style={{ color: 'white' }}>Reclamos</p>
            </div>
          </Link>
        </MenuItem>
      </Drawer>
    </>
  )
}

export default DrawerMenu
