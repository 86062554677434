import { Grid } from '@mui/material'
import React from 'react'
import './estilos/rseStyle.css'
import FormularioCompleto from '../components/formularios/FormularioCompleto'
import Footer from '../components/footer/Footer'
import { useLocation } from 'react-router-dom'

const FormCompleto = ({ contenido }) => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)

  const utm_campaign = params.get('utm_campaign')
  const utm_source = params.get('utm_source')
  const utm_medium = params.get('utm_medium')
  const utm_term = params.get('utm_term')

  const datos = {
    utm_campaign: utm_campaign || '',
    utm_source: utm_source || '',
    utm_medium: utm_medium || '',
    utm_term: utm_term || ''
  }
  return (
    <>
      <Grid>
        <Grid
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            minHeight: '851px'
          }}
        >
          <Grid item>
            <FormularioCompleto dato={datos} />
          </Grid>
        </Grid>
      </Grid>
      <Grid>
        <Grid>
          <Footer contenido={contenido} />
        </Grid>
      </Grid>

    </>
  )
}

export default FormCompleto
