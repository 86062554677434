import { Grid } from '@mui/material'
import React from 'react'
import Footer from '../components/footer/Footer'
import FooterList from '../components/footer/FooterList'
import RseComponent from '../components/rse/RseComponent'
import './estilos/rseStyle.css'

const Rse = ({ contenido, data }) => {
  return (
    <>
      <Grid>
        <Grid
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
        >
          <Grid item id='itemTituloPage'>
            <h2 id='h2'>
              RESPONSABILIDAD SOCIAL EMPRESARIAL
            </h2>
          </Grid>
          <Grid item id='gridItemContenedor'>
            <RseComponent contenido={data} />
          </Grid>
          <Grid item paddingBottom='15px'>
            <FooterList />
            <Footer contenido={contenido} />
          </Grid>
        </Grid>
      </Grid>

    </>
  )
}

export default Rse
