import { Grid } from '@mui/material'
import React from 'react'
import FooterList from '../footer/FooterList'
import { CardMV, ListCardMV } from './CardMisionVision'

const MisionVision = () => {
  return (
    <>
      <Grid
        style={{ paddingTop: '3vh', display: 'flex', flexDirection: 'column' }}
      >
        <Grid item>
          <h1 style={{ textAlign: 'center', color: '#00408E' }}>MISIÓN Y VISIÓN</h1>
        </Grid>
        <Grid item style={{ padding: '1em' }}>
          <CardMV />
          <ListCardMV />
        </Grid>
      </Grid>
      <Grid>
        <FooterList />
      </Grid>
    </>
  )
}
export default MisionVision
