import React from 'react'
import Typography from '@mui/material/Typography'
import './estilo.css'
import { Grid, Link, Paper } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const RseComponent = ({ contenido }) => {
  const naviegate = useNavigate()
  const rse = (contenido || []).map((item) => {
    return (
      <>
        <Paper
          elevation='1'
          style={{
            backgroundColor: '#00408E',
            borderRadius: '5px',
            padding: '10px',
            margin: '10px'
          }}
        >
          <Link
            onClick={() =>
              naviegate('/rseContent', {
                state: {
                  titulo: item.titulo,
                  contenido: item.contenido,
                  idImagen: item.imagen.id
                }
              })}
          >
            <Grid container alignItems='center' textAlign='center'>
              <Grid item xs={6}>
                <img
                  alt='Promociones'
                  src={
                    process.env.REACT_APP_API_URL_BASE +
                    `/Imagen/ShowImage/${item.imagen.id}`
                  }
                  style={{
                    width: '100%',
                    height: 'auto',
                    borderRadius: '15px'
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant='h5' style={{ color: 'white' }}>
                  {item.titulo}
                </Typography>
              </Grid>
            </Grid>
          </Link>
        </Paper>
      </>
    )
  })
  return <>{rse}</>
}
export default RseComponent
