import React from 'react'
import ControlPointIcon from '@mui/icons-material/ControlPoint'
import { Button, Grid } from '@mui/material'
import '../estilo.css'
import { Container } from '@mui/system'
import { Icon } from 'semantic-ui-react'
import { useNavigate } from 'react-router-dom'

const solicitudBad = () => {
/*  const naviegate = useNavigate();
  return (
    <>
      <Container>
        <Grid
          container
          id="SolicitudBadGrid"
        >
          <Grid item>
            <ControlPointIcon
             id="ControlPointIcon"
            ></ControlPointIcon>
          </Grid>
          <Grid item>
            <h2 id="h2">
              {" "}
              La solicitud fue rechazada por controles crediticios primarios
            </h2>
            <p id="p_SolicitudBad">
              Por mas información te podes comunicar al 0800 21 20
            </p>
          </Grid>
          <Grid item spacing={4}>
          <div className="div_buttom_img">
              <div container>
                <Icon
                  circular
                  name="phone"
                  className="Buttom_Img"
                  id="IconPhone"
                />
              </div>
              <a href="tel:+58908002120">

              <Button
                id="buttonCall"
                variant="contained"
                type="submit"
                >
                0800 2120
              </Button>
                </a>
            </div>
            <div className="div_buttom_img">
              <div container>
                <Icon
                  circular
                  name="home"
                  className="Buttom_Img"
                  id="IconPhone"
                />
              </div>
              <Button
              onClick={() => naviegate("/")}
                 id="buttonCall"
                variant="contained"
                type="submit"
              >
                Sitio Web
              </Button>
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  ); */
}

export default solicitudBad
