import { Grid } from '@mui/material'
import React from 'react'
import FooterList from '../components/footer/FooterList'
import Footer from '../components/footer/Footer'
import CardPromociones from '../components/tarjeta/CardPromociones'
import './estilos/promocionesStyle.css'

const PromocionesServicio = ({ contenido, imgPromoServ }) => {
  return (
    <>
      <Grid>
        <Grid
          id='gridPromo'
        >
          <Grid item id='itemTituloPage'>
            <h2 id='h2'>
              PROMOCIONES Y SERVICIOS
            </h2>
          </Grid>
          <Grid item id='gridItemContenedor'>
            <CardPromociones lista={imgPromoServ} />
          </Grid>
          <Grid item id='gridFooter'>
            <FooterList />
            <Footer contenido={contenido} />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default PromocionesServicio
