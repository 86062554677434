import {
  Grid,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material'
import SendIcon from '@mui/icons-material/Send'
import React from 'react'
import '../documento/Documento.css'

const DocumentoComp = ({ cont }) => {
  const documentos = (cont || []).map((item) => {
    return (
      <a
        href={
          process.env.REACT_APP_API_URL_BASE +
          `/Documentacion/ShowDoc/${item.id}`
        }
        target='_blank' rel='noopener noreferrer'
      >
        <ListItemButton className='lista_button'>
          <ListItemIcon id='lista_icon'>
            <SendIcon color='#00408E' />
          </ListItemIcon>
          <ListItemText id='lista_texto' primary={item.titulo} />
        </ListItemButton>
      </a>
    )
  })
  return (
    <>
      <Grid container id='listaDocGrid'>
        <Grid item id='listaTitulo'>
          <h2>DOCUMENTACIÓN</h2>
        </Grid>
        <Grid item>{documentos}</Grid>
      </Grid>
    </>
  )
}

export default DocumentoComp
