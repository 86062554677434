import { Grid } from '@mui/material'
import React from 'react'
import Documento from '../components/documento/Documento'
import Footer from '../components/footer/Footer'
import FooterList from '../components/footer/FooterList'
import './estilos/DocumentacionStyle.css'

const Documentacion = ({ contenido, documentos }) => {
  return (
    <>
      <Grid
        id='gridForm'
      >
        <Grid item id='gridItemPaper'>
          <Documento cont={documentos} />
        </Grid>
        <Grid item id='gridFooter'>
          <FooterList />
          <Footer contenido={contenido} />
        </Grid>
      </Grid>
    </>
  )
}

export default Documentacion
