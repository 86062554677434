import { Grid, Typography } from '@mui/material'
import React from 'react'
import '../footer/footer.css'
import imgSQLA from '../footer/img/LOGO-SQLA.png'
import imgLogoCreditoYa from '../footer/img/LOGO-CREDITO-YA.png'

const Footer = ({ contenido }) => {
  const textoFooter = (contenido || []).map((item) => {
    return (
      <>
        <p id='subtitlefootContent'>{item.titulo}</p>
        <p id='pfooterContent'>{item.contenido}</p>
      </>
    )
  })
  return (
    <div style={{ position: 'abstolute' }}>
      <Grid container id='logoTextFoot' marginTop={1}>
        <Grid container>
          <Grid item p={2}>
            <img src={imgSQLA} alt='SQLA' className='logoTextFott_SQLA' />
          </Grid>
          <Grid item p={2} paddingLeft={5}>
            <img
              src={imgLogoCreditoYa}
              alt='CreditoYa'
              className='logoTextFott_LogoCredito'
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container justify='center' xs='auto'>
        <Grid item>
          <Typography p={1}>{textoFooter}</Typography>
        </Grid>
      </Grid>
    </div>
  )
}

export default Footer
