import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'

import logoCredito from './img/LOGO-CREDITO-YA.png'
import { Grid } from '@mui/material'
import './NavMenu.css'
import { useNavigate } from 'react-router-dom'

const NavForm = () => {
  const navigate = useNavigate()

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container>
        <Grid item id='containerNav'>
          <AppBar id='barNav'>
            <Toolbar>

              <Grid container justifyContent='center' marginRight='50px'>
                <img
                  onClick={() => navigate('/')}
                  src={logoCredito}
                  alt='logo Credito Ya'
                  className='imgLogoAppBar'
                />
              </Grid>
            </Toolbar>
          </AppBar>
        </Grid>
      </Grid>
    </Box>
  )
}

export default NavForm
