import { Grid } from '@mui/material'
import React from 'react'
import Footer from '../components/footer/Footer'
import FooterList from '../components/footer/FooterList'
import FormularioReclamo from '../components/formularios/FormularioReclamos'
import './estilos/ReclamoStyle.css'

const Reclamo = ({ contenido }) => {
  return (
    <>
      <Grid id='gridQuienesSomos'>
        <Grid item paddingTop='15px'>
          <div>
            <h1
              id='titulo'
            >
              {' '}
              RECLAMOS
            </h1>
          </div>
        </Grid>
        <Grid id='Form_Reclamos'>
          <FormularioReclamo />
        </Grid>
      </Grid>
      <Grid item id='gridFooter'>
        <FooterList />
        <Footer contenido={contenido} />
      </Grid>
    </>
  )
}

export default Reclamo
