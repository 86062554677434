import React from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { Button, Container, Grid, TextField } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import InputMask from 'react-input-mask'
import './FormStyle.css'
import axios from 'axios'

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const validationSchema = yup.object({
  ci: yup
    .string('Ingrese su ci')
    .test('si', 'cédula no Valida', isDocumentValid)
    .min(1, 'Ingrese su ci')
    .required('el campo ci es obligatorio'),
  celular: yup
    .string()
    .matches(phoneRegExp, 'numero de teléfono no valido')
    .min(8, 'ingrese su celular')
    .max(9, 'ingrese un numero correcto')
    .required('el campo Nombre es obligatorio')
})

function isDocumentValid (value) {
  value = (value || '').padStart(8, '0').replace(/[^0-9]/g, '')
  return (
    (2 * value[0] +
        9 * value[1] +
        8 * value[2] +
        7 * value[3] +
        6 * value[4] +
        3 * value[5] +
        4 * value[6] +
        1 * value[7]) %
        10 ===
        0
  )
}

const apiPreLeed = (data) => {
  const obj = {
    Cedula: data.ci.replace(/[^0-9]/g, ''),
    Telefono: data.celular.replace(/[^0-9]/g, '')
  }

  axios
    .post(process.env.REACT_APP_CONSULTA_API + 'PreConsulta/pre-consulta', obj)
    .then((response) => {
      if (response.data.codigo === 1) {

      } else {

      }
    }
    )
    .catch((error) => {

    })
}

const FormHome = () => {
  const naviegate = useNavigate()
  const formik = useFormik({
    initialValues: {
      ci: '',
      celular: ''
    },
    validationSchema,
    onSubmit: (values) => {
      apiPreLeed(values)
      naviegate('/formularioCompleto', {
        state: { ci: formik.values.ci.replace(/[^0-9]/g, ''), celular: formik.values.celular.replace(/[^0-9]/g, '') }
      })
    }
  })
  return (
    <>
      <Container>
        <Grid container className='Grid_FH'>
          <Grid item xs={12}>
            <form onSubmit={formik.handleSubmit}>
              <div>
                <InputMask
                  mask='9.999.999-9'
                  value={formik.values.ci}
                  onChange={formik.handleChange}
                  disabled={false}
                  maskChar={null}
                >

                  {() => <TextField
                    fullWidth
                    id='ci'
                    name='ci'
                    placeholder='Ingrese su ci'
                    value={formik.values.ci}
                    onChange={formik.handleChange}
                    error={formik.touched.ci && Boolean(formik.errors.ci)}
                    helperText={formik.touched.ci && formik.errors.ci}
                    className='TextField'
                    size='small'
                         />}
                </InputMask>
              </div>
              <br />
              <div>
                <TextField
                  fullWidth
                  id='celular'
                  name='celular'
                  placeholder='Ingrese su cel'
                  value={formik.values.celular}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.celular && Boolean(formik.errors.celular)
                  }
                  helperText={formik.touched.celular && formik.errors.celular}
                  className='TextField'
                  size='small'
                />
              </div>
              <br />
              <div className='Form_Buttom'>
                <Button
                  id='Button_Form'
                  variant='contained'
                  type='submit'
                >
                  Pedilo Ya
                </Button>
              </div>
            </form>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}
export default FormHome
