import React from 'react'
import { Grid, Paper } from '@mui/material'

const CardPromociones = ({ lista }) => {
  const promo = (lista || []).map((item) => {
    return (
      <Grid
        style={{ display: 'flex', justifyContent: 'center', padding: '5px' }}
      >
        <Grid item>
          <Paper
            elevation='1'
            style={{
              backgroundColor: '#00408E',
              borderRadius: '5px',
              maxWidth: '500px'
            }}
          >
            <div style={{ padding: '5px' }}>
              <img
                alt='Promociones'
                src={
                    process.env.REACT_APP_API_URL_BASE +
                    `/Imagen/ShowImage/${item.imagen.id}`
                  }
                style={{ width: '100%', height: 'auto' }}
              />
            </div>
          </Paper>
        </Grid>
      </Grid>
    )
  })
  return <>{promo}</>
}

export default CardPromociones
