import React from 'react'
import Carrousel from '../components/carousel/Carousel'
import SectionsIcons from '../components/sections/SectionsIcons'
import FooterList from '../components/footer/FooterList'
import Footer from '../components/footer/Footer'
import FormHome from '../components/FormCi_Tel/FormHome'
import Loading from '../components/loading/Loading'

const Home = ({ lista, contenido, loading }) => {
  return (
    <>
      <Carrousel lista={lista} />
      <SectionsIcons />
      <FooterList />
      <Footer contenido={contenido} />
    </>
  )
}

export default Home
