import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import {
  Button,
  Container,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField
} from '@mui/material'
import Loading from '../loading/Loading'
import SolicitudBad from './pantallaRespuesta/SolicitudBad'
import SolicitudOk from './pantallaRespuesta/SolicitudOk'
import Checkbox from '@mui/material/Checkbox'
import axios from 'axios'
import diferenceInYears from 'date-fns/differenceInYears'
import InputMask from 'react-input-mask'
import { useLocation } from 'react-router-dom'

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const validationSchema = yup.object({
  cedula: yup
    .string('Ingrese una cédula')
    .test('validator_Ci', function (value) {
      const valido = isDocumentValid(value)
      if (!valido) {
        return this.createError({ message: 'Ingrese una cédula correcta' })
      } else {
        return true
      }
    })
    .required('Ingrese una cédula'),
  Telefono: yup
    .string()
    .matches(phoneRegExp, 'numero de telefono no valido')
    .min(8, 'ingrese su Telefono')
    .max(9, 'ingrese un numero correcto')
    .required('el campo Nombre es obligatorio'),
  nombre: yup
    .string('Ingrese su nombre').test('validarNombre', function (value) {
      if (!/^([ a-zA-ZñÑáéíóúÁÉÍÓÚ])+$/i.test(value)) {
        return this.createError({ message: 'el nombre solo puede contener letras' })
      } else {
        return true
      }
    })
    .min(2, 'Debe de ingresar mínimo 2 letras')
    .max(25, 'Nombre debe contener como máximo 25 caracteres')
    .required('El campo Nombre es obligatorio'),
  apellido: yup
    .string('Ingrese su nombre').test('validarApellido', function (value) {
      if (!/^([ a-zA-ZñÑáéíóúÁÉÍÓÚ])+$/i.test(value)) {
        return this.createError({ message: 'el apellido solo puede contener letras' })
      } else {
        return true
      }
    })
    .min(2, 'Debe de ingresar mínimo 2 letras')
    .max(25, 'Apellido debe contener como máximo 25 caracteres')
    .required('El campo Nombre es obligatorio'),
  email: yup
    .string()
    .email('Ingrese un formato correcto para el correo')
    .required('El campo fecha es requerido'),
  accepted: yup.boolean().test('accepted', 'Required', (val) => {
    return val
  }),
  fechaNacimiento: yup
    .date().test('validarFecha', function (value) {
      if (diferenceInYears(new Date(), new Date(value)) < 23 || diferenceInYears(new Date(), new Date(value)) >= 75) {
        return this.createError({ message: 'Debes de tener entre 23 y 74 años inclusive' })
      } else {
        return true
      }
    })
    .required('Ingrese una fecha'),
  viaComunicacionId: yup.string().required('Seleccione un medio de contacto')
})
function isDocumentValid (value) {
  value = (value || '').replace(/\D/g, '')
  value = (value || '').padStart(8, '0')
  return (
    (2 * value[0] +
      9 * value[1] +
      8 * value[2] +
      7 * value[3] +
      6 * value[4] +
      3 * value[5] +
      4 * value[6] +
      1 * value[7]) %
      10 ===
    0
  )
}

const tipoLlamada = [
  {
    value: '2',
    label: 'WhatApp'
  },
  {
    value: '3',
    label: 'Teléfono'
  },
  {
    value: '1',
    label: 'Email'
  }
]
const FormularioCompletoCampana = () => {
  const location = useLocation
  const [loading, setLoading] = useState(false)
  const [validacion, setValidacion] = useState(0)
  const ci = (location().state && location().state.ci) || ''
  const cel = (location().state && location().state.Telefono) || ''
  const campaña = (location().state && location().state.UtmCampania) || ''
  const fuente = (location().state && location().state.UtmFuente) || ''
  const medio = (location().state && location().state.UtmMedio) || ''
  const termino = (location().state && location().state.UtmTermino) || ''
  const apiConsultaForm = (obj) => {
    setLoading(true)
    axios
      .post(process.env.REACT_APP_CONSULTA_API + 'consulta/solicitud', obj)
      .then((response) => {
        if (response.data.codigo === 1) {
          setValidacion(2)
          setLoading(false)
        } else {
          setValidacion(1)
          setLoading(false)
        }
      }
      )
      .catch((error) => {
        setValidacion(1)
        setLoading(false)
      })
  }
  const formik = useFormik({
    initialValues: {
      nombre: '',
      apellido: '',
      fechaNacimiento: '',
      email: '',
      estaEnClearing: true,
      viaComunicacionId: '',
      accepted: true,
      UtmCampania: campaña,
      UtmFuente: fuente,
      UtmMedio: medio,
      UtmTermino: termino,
      cedula: ci,
      Telefono: cel
    },
    validationSchema,
    onSubmit: (values) => {
      values.cedula = values.cedula.replace(/[^0-9]/g, '')
      apiConsultaForm(values)
    }
  })
  return (
    <>
      <Loading loading={loading} />
      {validacion === 1
        ? (
          <SolicitudBad />
          )
        : validacion === 2
          ? (
            <SolicitudOk />
            )
          : (
            <Container>
              <Grid container className='Grid_Form'>
                <Grid item className='Form_Text'>
                  <h1> ¿NECESITÁS EFECTIVO?</h1>
                  <p>
                    Para finalizar la solicitud, solo quedan estos campos para
                    completar
                  </p>
                </Grid>
                <Grid item xs={12}>
                  <form onSubmit={formik.handleSubmit}>
                    <div className='Form_text_Field'>
                      <InputMask
                        mask='9.999.999-9'
                        value={formik.values.cedula}
                        onChange={formik.handleChange}
                        disabled={false}
                        maskChar={null}
                      >

                        {() => <TextField
                          fullWidth
                          id='cedula'
                          name='cedula'
                          label='Ingrese su ci'
                          value={formik.values.cedula}
                          onChange={formik.handleChange}
                          error={formik.touched.cedula && Boolean(formik.errors.cedula)}
                          helperText={formik.touched.cedula && formik.errors.cedula}
                               />}
                      </InputMask>

                    </div>
                    <div className='Form_text_Field'>
                      <TextField
                        fullWidth
                        id='Telefono'
                        name='Telefono'
                        label='Ingrese su cel'
                        value={formik.values.Telefono}
                        onChange={formik.handleChange}
                        error={
                        formik.touched.Telefono && Boolean(formik.errors.Telefono)
                      }
                        helperText={
                        formik.touched.Telefono && formik.errors.Telefono
                      }
                      />
                    </div>
                    <div className='Form_text_Field'>
                      <TextField
                        fullWidth
                        id='nombre'
                        name='nombre'
                        type='text'
                        label='Ingrese su Nombre'
                        value={formik.values.nombre}
                        onChange={formik.handleChange}
                        error={
                      formik.touched.nombre && Boolean(formik.errors.nombre)
                    }
                        helperText={formik.touched.nombre && formik.errors.nombre}
                      />
                    </div>
                    <div className='Form_text_Field'>
                      <TextField
                        fullWidth
                        id='apellido'
                        name='apellido'
                        type='text'
                        label='Ingrese su apellido'
                        value={formik.values.apellido}
                        onChange={formik.handleChange}
                        error={
                      formik.touched.apellido && Boolean(formik.errors.apellido)
                    }
                        helperText={
                      formik.touched.apellido && formik.errors.apellido
                    }
                      />
                    </div>
                    <div className='Form_text_Field'>
                      <TextField
                        fullWidth
                        id='fechaNacimiento'
                        name='fechaNacimiento'
                        type='date'
                        placeholder='dd/mm/aaaa'
                        value={formik.values.fechaNacimiento}
                        onChange={formik.handleChange}
                        error={
                      formik.touched.fechaNacimiento &&
                      Boolean(formik.errors.fechaNacimiento)
                    }
                        helperText={
                      formik.touched.fechaNacimiento &&
                      formik.errors.fechaNacimiento
                    }
                      />
                    </div>
                    <div className='Form_text_Field'>
                      <TextField
                        fullWidth
                        id='email'
                        name='email'
                        label='Mail'
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                      />
                    </div>
                    <div className='Form_text_Field'>
                      <TextField
                        fullWidth
                        id='viaComunicacionId'
                        select
                        label='Medio de contacto'
                        onChange={formik.handleChange}
                        defaultValue='Email'
                        helperText='seleccionar medio de contacto'
                        name='viaComunicacionId'
                      >
                        {tipoLlamada.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                    <div className='Form_text_Field'>
                      <b>Esta en clearing ? :</b>
                      <RadioGroup
                        row
                        id='estaEnClearing'
                        aria-labelledby='demo-row-radio-buttons-group-label'
                        name='row-radio-buttons-group'
                        value={formik.values.estaEnClearing}
                        onChange={formik.handleChange}
                      >
                        <FormControlLabel
                          value
                          control={<Radio />}
                          name='estaEnClearing'
                          label='SI'
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          name='estaEnClearing'
                          label='NO'
                        />
                      </RadioGroup>
                    </div>
                    <div className='Form_text_Field'>
                      <FormControlLabel
                        value={formik.values.accepted}
                        name='accepted'
                        onChange={formik.handleChange}
                        control={<Checkbox />}
                        label='He leído y acepto los Terminos y Condiciones'
                      />
                      {formik.values.accepted
                        ? null
                        : (
                          <p style={{ color: 'red' }}>Acepte los terminos</p>
                          )}
                    </div>

                    <div className='Form_Buttom'>
                      <Button
                        style={{ backgroundColor: '#00408E' }}
                        variant='contained'
                        type='submit'
                      >
                        Pedilo Ya
                      </Button>
                    </div>

                  </form>
                </Grid>
              </Grid>
            </Container>
            )}
    </>
  )
}

export default FormularioCompletoCampana
