import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { Button, Grid, TextField, Typography, Modal, Box } from '@mui/material'
import InputMask from 'react-input-mask'
import './estiloFormularioReclamos.css'
import axios from 'axios'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import Loading from '../loading/Loading'

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const validationSchema = yup.object({
  nombre: yup
    .string('Ingrese su nombre').test('validarNombre', function (value) {
      if (!/^([ a-zA-ZñÑáéíóúÁÉÍÓÚ])+$/i.test(value)) {
        return this.createError({ message: 'el nombre solo puede contener letras' })
      } else {
        return true
      }
    })
    .min(2, 'Debe de ingresar mínimo 2 letras')
    .max(25, 'Nombre debe contener como máximo 25 caracteres')
    .required('El campo Nombre es obligatorio'),
  apellido: yup
    .string('Ingrese su nombre').test('validarApellido', function (value) {
      if (!/^([ a-zA-ZñÑáéíóúÁÉÍÓÚ])+$/i.test(value)) {
        return this.createError({ message: 'el apellido solo puede contener letras' })
      } else {
        return true
      }
    })
    .min(2, 'Debe de ingresar mínimo 2 letras')
    .max(25, 'Apellido debe contener como máximo 25 caracteres')
    .required('El campo Nombre es obligatorio'),
  email: yup
    .string()
    .email('Ingrese un formato correcto para el correo')
    .required('El campo fecha es requerido'),
  textMessage: yup
    .string()
    .min(10, 'mensaje muy corto')
    .required('Se debe de ingresar un mensaje'),
  cedula: yup
    .string('Ingrese una cédula')
    .test('validator_Ci', function (value) {
      if (value != null) {
        const valido = isDocumentValid(value.replace(/[^0-9]/g, ''))
        if (!valido) {
          return this.createError({ message: 'Ingrese una cédula correcta' })
        }
        return true
      }
    })
    .required('Ingrese una cédula'),
  celular: yup
    .string()
    .matches(phoneRegExp, 'numero de telefono no valido')
    .min(7, 'ingrese su celular')
    .max(9, 'ingrese un numero correcto')
    .required('el campo Nombre es obligatorio')
})

function isDocumentValid (value) {
  value = value.padStart(8, '0')
  return (
    (2 * value[0] +
        9 * value[1] +
        8 * value[2] +
        7 * value[3] +
        6 * value[4] +
        3 * value[5] +
        4 * value[6] +
        1 * value[7]) %
        10 ===
      0
  )
}

const FormularioReclamos = () => {
  const [openConfirm, setOpenConfirm] = useState(false)
  const handleCloseConfirm = () => { setOpenConfirm(false); window.location.reload(true) }
  const [openDelete, setOpenDelete] = useState(false)
  const handleCloseDelete = () => { setOpenDelete(false) }
  const [loading, setLoading] = useState(false)

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4
  }

  const envioForm = (obj) => {
    setLoading(true)
    axios
      .post(process.env.REACT_APP_API_URL_BASE + '/Reclamo/Agregar', obj)
      .then((response) => {
        console.log(response)
        if (response.status === 200) {
          setLoading(false)
          setOpenConfirm(true)
          setTimeout(() => {
            handleCloseConfirm(false)
          }, 8000)
        } else {
          setLoading(false)
          setOpenDelete(true)
          setTimeout(() => {
            handleCloseDelete(false)
          }, 8000)
        }
      }
      )
      .catch((error) => {
        setLoading(false)
        setOpenDelete(true)
        setTimeout(() => {
          handleCloseDelete(false)
        }, 12000)
      })
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      nombre: '',
      apellido: '',
      email: '',
      celular: '',
      cedula: '',
      textMessage: ''
    },
    validationSchema,
    onSubmit: (values) => {
      envioForm(values)
    }
  })

  const modalRespuestaOk = (
    <>

      <Grid container id='containerModal'>
        <Grid item id='itemModalLogo'>
          <CheckCircleOutlineIcon id='logoModal' />
        </Grid>
        <Grid item id='itemModalTexto'>
          <Typography><h4>Su Reclamo fue correctamente Enviado</h4></Typography> <br />
          <Typography><p>CREDITO YA, se contactara a la brevedad por su consulta</p></Typography>
        </Grid>
      </Grid>

    </>
  )

  const modalRespuestaError = (
    <>

      <Grid container id='containerModal'>
        <Grid item id='itemModalLogo'>
          <ErrorOutlineIcon id='logoModal' />
        </Grid>
        <Grid item id='itemModalTexto'>
          <Typography><h3>Su reclamo no fue enviado</h3></Typography> <br />
          <Typography><p>Inténtelo nuevamente mas tarde</p></Typography>
        </Grid>
      </Grid>

    </>
  )

  return (
    <>
      <Loading loading={loading} />
      <form onSubmit={formik.handleSubmit}>
        <Grid container id='gridContainerNombreApellido'>
          <Grid item xs={6} md={12} id='itemGridDatosOtros'>
            <TextField
              id='nombre'
              name='nombre'
              fullWidth
              label='Nombre'
              value={formik.values.nombre}
              onChange={formik.handleChange}
              error={
                          formik.touched.nombre && Boolean(formik.errors.nombre)
                        }
              helperText={
                        formik.touched.nombre && formik.errors.nombre
                      }
            />
          </Grid>
          <Grid item xs={6} md={12} id='itemGridDatosOtros'>
            <TextField
              id='apellido'

              name='apellido'
              label='Apellido'
              fullWidth
              value={formik.values.apellido}
              onChange={formik.handleChange}
              error={
                          formik.touched.apellido && Boolean(formik.errors.apellido)
                        }
              helperText={
                        formik.touched.apellido && formik.errors.apellido
                      }
            />
          </Grid>
        </Grid>
        <Grid container id='gridContainerDatosOtros'>

          <Grid item xs={12} id='itemGridDatosOtros'>
            <InputMask
              mask='9.999.999-9'
              value={formik.values.cedula}
              onChange={formik.handleChange}
              disabled={false}
              maskChar={null}
            >

              {() => <TextField
                fullWidth
                id='cedula'
                name='cedula'
                label='Ingrese su ci'
                value={formik.values.cedula}
                onChange={formik.handleChange}
                error={formik.touched.cedula && Boolean(formik.errors.cedula)}
                helperText={formik.touched.cedula && formik.errors.cedula}
                     />}
            </InputMask>
          </Grid>
          <Grid item xs={12} id='itemGridDatosOtros'>
            <TextField
              fullWidth
              id='celular'
              name='celular'
              label='Ingrese su cel'
              value={formik.values.celular}
              onChange={formik.handleChange}
              error={
                          formik.touched.celular && Boolean(formik.errors.celular)
                        }
              helperText={
                        formik.touched.celular && formik.errors.celular
                      }
            />
          </Grid>
          <Grid item xs={12} id='itemGridDatosOtros'>
            <TextField
              fullWidth
              id='email'
              name='email'
              label='Correo Electrónico'
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item xs={12} id='itemGridDatosOtros'>
            <TextField
              multiline
              fullWidth
              id='textMessage'
              name='textMessage'
              label='Escribir'
              value={formik.values.textMessage}
              onChange={formik.handleChange}
              error={formik.touched.textMessage && Boolean(formik.errors.textMessage)}
              helperText={formik.touched.textMessage && formik.errors.textMessage}
            />
          </Grid>
        </Grid>
        <Grid container id='gridButtonForm'>
          <Grid item>
            <Button
              style={{ backgroundColor: '#00408E' }}
              variant='contained'
              type='submit'
            >
              Enviar
            </Button>
          </Grid>
        </Grid>

      </form>

      <Modal
        open={openConfirm}
        onClose={handleCloseConfirm}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          {modalRespuestaOk}
        </Box>
      </Modal>
      <Modal
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          {modalRespuestaError}
        </Box>
      </Modal>
    </>
  )
}

export default FormularioReclamos
