import { Grid, Paper } from '@mui/material'
import React from 'react'
import Footer from '../components/footer/Footer'
import FooterList from '../components/footer/FooterList'
import { useLocation } from 'react-router-dom'
import './estilos/rseStyle.css'

const RseContenido = ({ footer }) => {
  const footerContenido = footer
  const location = useLocation
  const titulo = location().state.titulo
  const contenido = location().state.contenido
  const idImagen = location().state.idImagen

  return (
    <>
      <Grid>
        <Grid id='gridRse'>
          <Grid item id='itemTituloPage'>
            <h2 id='h2'>RESPONSABILIDAD SOCIAL EMPRESARIAL</h2>
          </Grid>
          <Grid item id='gridItemContenedor'>
            <Grid container>
              <Grid item xs={12}>
                <Paper id='paperRse'>
                  <h2 id='h2Paper'>{titulo}</h2>
                </Paper>
              </Grid>
              <Grid item xs={12} id='gridItemPaper'>
                <p>{contenido}</p>
              </Grid>
              <Grid item xs={12} id='gridItemPaper'>
                <img
                  alt='Promociones'
                  src={
                    process.env.REACT_APP_API_URL_BASE +
                    `/Imagen/ShowImage/${idImagen}`
                  }
                  id='imgPaper'
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item paddingBottom='15px'>
            <FooterList />
            <Footer contenido={footerContenido} />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default RseContenido
