import React from 'react'
import { Button, Grid } from '@mui/material'
import '../footerBottom/estilo.css'
import { useNavigate } from 'react-router-dom'

const FotterButtom = () => {
  const naviegate = useNavigate()
  return (
    <>
      <footer style={{ position: 'fixed', bottom: 0, width: '100%' }}>
        <Grid container />
      </footer>
    </>
  )
}
export default FotterButtom
