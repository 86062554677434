import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'

import { Button, TextField, Grid } from '@mui/material'
import '../formularios/estilo.css'
import { Container } from '@mui/system'
import Loading from '../loading/Loading'
import '../loading/Loading.css'
import InputMask from 'react-input-mask'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const validationSchema = yup.object({
  cedula: yup
    .string('Ingrese una cédula')
    .test('validator_Ci', function (value) {
      const valido = isDocumentValid(value)
      if (!valido) {
        return this.createError({ message: 'Ingrese una cédula correcta' })
      } else {
        return true
      }
    })
    .required('Ingrese una cédula'),
  celular: yup
    .string()
    .matches(phoneRegExp, 'numero de telefono no valido')
    .min(8, 'ingrese su celular')
    .max(9, 'ingrese un numero correcto')
    .required('el campo Nombre es obligatorio')
})

function isDocumentValid (value) {
  value = (value || '').replace(/\D/g, '') // remove non numeric
  value = (value || '').padStart(8, '0') // pad 0 at beginning
  return (
    (2 * value[0] +
      9 * value[1] +
      8 * value[2] +
      7 * value[3] +
      6 * value[4] +
      3 * value[5] +
      4 * value[6] +
      1 * value[7]) %
      10 ===
    0
  )
}

const apiPreLeed = (data) => {
  const obj = {
    Cedula: data.cedula.replace(/[^0-9]/g, ''),
    Telefono: data.celular.replace(/[^0-9]/g, '')
  }

  axios
    .post(process.env.REACT_APP_CONSULTA_API + 'PreConsulta/pre-consulta', obj)
    .then((response) => {
      if (response.data.codigo === 1) {

      } else {

      }
    }
    )
    .catch((error) => {

    })
}

const Formulario = () => {
  const location = useLocation
  const [loading, setLoading] = useState()
  const [bandera, setBandera] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 8000)
  })
  const ci = (location().state && location().state.ci) || ''
  const cel = (location().state && location().state.celular) || ''

  const formik = useFormik({
    initialValues: {
      cedula: ci,
      celular: cel
    },
    validationSchema,
    onSubmit: (values) => {
      apiPreLeed(values)
      setLoading(true)
      setBandera(1)
    }
  })
  const navigator = useNavigate()
  return (
    <>
      {bandera
        ? (
            navigator('/formularioCompleto', {
              state: { ci: formik.values.cedula.replace(/[^0-9]/g, ''), celular: formik.values.celular }
            })

          )
        : (
          <div>
            <Loading loading={loading} />
            <Container>
              <Grid container className='Grid_Form'>
                <Grid item className='Form_Text'>
                  <h1> ¿NECESITAS EFECTIVO?</h1>
                  <p>Te brindamos un préstamo rápido y sin complicaciones</p>
                </Grid>

                <Grid item xs={12}>
                  <form onSubmit={formik.handleSubmit}>
                    <div className='Form_text_Field'>
                      <InputMask
                        mask='9.999.999-9'
                        value={formik.values.cedula}
                        onChange={formik.handleChange}
                        disabled={false}
                        maskChar={null}
                      >

                        {() => <TextField
                          fullWidth
                          id='cedula'
                          name='cedula'
                          label='Ingrese su ci'
                          value={formik.values.cedula}
                          onChange={formik.handleChange}
                          error={formik.touched.cedula && Boolean(formik.errors.cedula)}
                          helperText={formik.touched.cedula && formik.errors.cedula}
                               />}
                      </InputMask>

                    </div>
                    <div className='Form_text_Field'>
                      <TextField
                        fullWidth
                        id='celular'
                        name='celular'
                        label='Ingrese su cel'
                        value={formik.values.celular}
                        onChange={formik.handleChange}
                        error={
                        formik.touched.celular && Boolean(formik.errors.celular)
                      }
                        helperText={
                        formik.touched.celular && formik.errors.celular
                      }
                      />
                    </div>
                    <div className='Form_Buttom'>
                      <Button
                        style={{ backgroundColor: '#00408E' }}
                        variant='contained'
                        type='submit'
                      >
                        Pedilo Ya
                      </Button>
                    </div>
                  </form>
                </Grid>
              </Grid>
            </Container>
          </div>
          )}
    </>
  )
}

export default Formulario
