import { Grid } from '@mui/material'
import React from 'react'
import Preguntas from '../components/preguntasFrec/Preguntas'
import FooterList from '../components/footer/FooterList'
import Footer from '../components/footer/Footer'
import './estilos/preguntasFrecStyle.css'

const PreguntasFrecuentes = ({ contenido, preguntas }) => {
  return (
    <Grid>
      <Grid
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
      >
        <Grid item id='gridPromo'>
          <Preguntas contenido={preguntas} />
        </Grid>
        <Grid item id='gridFooter'>
          <FooterList />
          <Footer contenido={contenido} />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PreguntasFrecuentes
