import { Grid, Link } from '@mui/material'
import React from 'react'
import '../footer/footer.css'

import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined'
import InstagramIcon from '@mui/icons-material/Instagram'
import YouTubeIcon from '@mui/icons-material/YouTube'
import TwitterIcon from '@mui/icons-material/Twitter'
import LinkedInIcon from '@mui/icons-material/LinkedIn'

const FooterList = () => {
  return (
    <div>
      <Grid container id='FooterList'>
        <Grid item p={1} id='gridList'>
          <list>
            <dl>
              <dt>
                <Link color='inherit' underline='none' href='/promociones'>
                  Promociones
                </Link>
              </dt>
              <dt>
                <Link color='inherit' underline='none' href='/preguntas'>
                  Preguntas Frecuentes
                </Link>
              </dt>
              <dt>
                <Link color='inherit' underline='none' href='/misvis'>
                  Misión y Visión
                </Link>
              </dt>
              <dt>
                <Link color='inherit' underline='none' href='/documento'>
                  Documentación
                </Link>
              </dt>
              <dt>
                <Link color='inherit' underline='none' href='/rse'>
                  Rse
                </Link>
              </dt>
              <dt>
                <Link color='inherit' underline='none' href='/reclamo'>
                  Reclamos
                </Link>
              </dt>
            </dl>
          </list>
        </Grid>
        <Grid item p={2} id='footIcon'>
          <div id='icons'>
            <Link
              href='https://www.facebook.com/creditoyauy'
              target='_blank'
              rel='noopener noreferrer'
            >
              <FacebookOutlinedIcon className='icon_footer' />
            </Link>
            <Link
              href='https://www.instagram.com/creditoyauy/?hl=es'
              target='_blank'
              rel='noopener noreferrer'
            >
              <InstagramIcon className='icon_footer' />
            </Link>
            <Link
              href='https://www.youtube.com/watch?v=5whPyT09I8w'
              target='_blank'
              rel='noopener noreferrer'
            >
              <YouTubeIcon className='icon_footer' />
            </Link>
            <Link
              href='https://twitter.com/creditoyauy'
              target='_blank'
              rel='noopener noreferrer'
            >
              <TwitterIcon className='icon_footer' />
            </Link>
            <Link
              href='https://uy.linkedin.com/company/creditoyauy?original_referer=https%3A%2F%2Fwww.google.com%2F'
              target='_blank'
              rel='noopener noreferrer'
            >
              <LinkedInIcon className='icon_footer' />
            </Link>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
export default FooterList
